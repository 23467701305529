import {WorkTime} from "../volunteers-list";
import moment from "./moment";

const isWorksNow = (workTime: WorkTime[], timeZone: number) => {
    const filteredDates = workTime.filter((time) => {
        const start = moment().utcOffset(timeZone).day(time.day).hour(time.start[0]).minute(time.start[1]).utc();
        const end = moment().utcOffset(timeZone).day(time.day).hour(time.end[0]).minute(time.end[1]).utc();

        return moment().isBetween(start, end);
    });

    return filteredDates.length > 0
};

export default isWorksNow;
