import {WorkTime} from "../../../volunteers-list";
import moment from "utils/moment";
import React from "react";
import "./work-time-renderer.css"

const WorkTimeRenderer = (props: { workTime: WorkTime[], timeZone: number }) => {
    const {workTime, timeZone} = props;
    const formatStart = 'ddd. HH:mm';
    const formatEnd = 'HH:mm';
    return (
        <ul className="work-time">
            {workTime.map((t, key) => {
                const start = moment().utcOffset(timeZone, false).day(t.day).hour(t.start[0]).minute(t.start[1]);
                const end = moment().utcOffset(timeZone, true).day(t.day).hour(t.end[0]).minute(t.end[1]);
                return (
                    <li key={key} className="work-time__item">
                        {start.tz('Europe/Moscow').format(formatStart)}-{end.tz('Europe/Moscow').format(formatEnd)}
                    </li>
                )
            })}
        </ul>
    )
};

export default WorkTimeRenderer
