export type Volunteers = {
  phone: string;
  id: number;
  workTime: WorkTime[];
  timeZone: number;
  city: string;
};

export type WorkTime = {
  day: number;
  start: [number, number];
  end: [number, number];
};

const volunteers: Volunteers[] = [
  {
    id: 1,
    phone: "79154958899",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 3,
    phone: "79166491388",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 4,
    phone: "79251849197",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 6,
    phone: "79152866824",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 5,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 7,
    phone: "79166214762",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 6,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 8,
    phone: "79164451664",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 10,
    phone: "79526544797",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 11,
    phone: "79653151711",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 6,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 14,
    phone: "79670688989",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 16,
    phone: "79998049169",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 18,
    phone: "79612555575",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 21,
    phone: "79961818999",
    timeZone: 5,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 23,
    phone: "79036022084",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 2,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 24,
    phone: "79027527104",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 6,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 25,
    phone: "79099271770",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [22, 0],
      },
    ],
  },
  {
    id: 26,
    phone: "79671059488",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 6,
        start: [12, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 27,
    phone: "79180823796",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 6,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 28,
    phone: "79099838643",
    timeZone: 4,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 30,
    phone: "79099335498",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 31,
    phone: "79163124169",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 2,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 32,
    phone: "79852479201",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 37,
    phone: "79851594416",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 39,
    phone: "79037079921",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 0,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 41,
    phone: "79998679375",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 42,
    phone: "79536625087",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 0,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 43,
    phone: "79035707133",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 44,
    phone: "79169685576",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 45,
    phone: "79933700802",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 4,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 47,
    phone: "79162989797",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 3,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 48,
    phone: "79110180009",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 0,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 51,
    phone: "79777487312",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 0,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 52,
    phone: "79778908505",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 1,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 53,
    phone: "79175672848",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 2,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  // {
  //   id: 54,
  //   phone: "79887628141",
  //   timeZone: 3,
  //   city: "Moscow",
  //   workTime: [
  //     {
  //       day: 0,
  //       start: [8, 0],
  //       end: [23, 59],
  //     },
  //   ],
  // },
  {
    id: 55,
    phone: "79139655055",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 2,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
  {
    id: 56,
    phone: "79167337373",
    timeZone: 3,
    city: "Moscow",
    workTime: [
      {
        day: 2,
        start: [8, 0],
        end: [23, 59],
      },
    ],
  },
];

export default volunteers;
