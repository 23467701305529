import React, { useState } from "react";
import { Badge, Layout, Table, Typography } from "antd";
import "./App.css";
import volunteers, { Volunteers, WorkTime } from "../volunteers-list";
import WorkTimeRenderer from "components/renderer/WorkTimeRenderer/WorkTimeRenderer";
import isWorksNow from "../utils/isWorksNow";
import { ColumnType } from "antd/es/table";

const { Content, Footer } = Layout;
const { Title, Text } = Typography;

function App() {
  const [pageDisabled] = useState(false);

  if (pageDisabled) {
    return (
      <Layout
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title>Сайт недоступен</Title>
      </Layout>
    );
  }

  const sortedVolunters = volunteers.sort((a, b) => {
    return (
      Number(isWorksNow(b.workTime, b.timeZone)) -
      Number(isWorksNow(a.workTime, a.timeZone))
    );
  });

  return (
    <Layout style={{ display: "flex", minHeight: "100vh" }}>
      <Content style={{ padding: 24 }}>
        <Title level={3}>Скорая психологическая служба волонтеров</Title>
        <div className="layout-background" style={{ minHeight: 360 }}>
          <Text style={{ padding: 24, display: "block" }}>
            Здесь вы найдете номера телефонов волонтеров, к которым вы можете
            обратиться прямо сейчас.{" "}
            <b>График работы волонтеров указан по Московскому времени</b>{" "}
          </Text>
          <Text style={{ padding: 24, display: "block" }}>
            <Badge color="green" />- Работает сейчас <br />
            <Badge color="red" />- Не рабочее время
          </Text>
          <Table
            size="small"
            dataSource={sortedVolunters}
            columns={columns}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </div>
      </Content>
      <Footer className="footer">
        Создано при поддержке
        <div className="footer__content">
          <a
            href="http://psy-school.info/"
            className="footer__content__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/logo/school.png"
              alt="Высшая школа практической психолгии и бизнеса"
            />
          </a>
          <a
            href="http://union-pkp.ru/"
            className="footer__content__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/logo/union.png"
              alt="Союз психологов-консультантов и психотерапевтов"
            />
          </a>
          <a
            href="http://psyholog-911.ru/"
            className="footer__content__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/logo/cpp.png"
              alt="ЦЕНТР ПРАКТИЧЕСКОЙ ПСИХОЛОГИИ «ПРИЗ»"
            />
          </a>
        </div>
      </Footer>
    </Layout>
  );
}

const columns: ColumnType<Volunteers>[] = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: 10,
  },
  {
    title: "",
    dataIndex: "workTime",
    key: "status",
    render: (workTime: WorkTime[], { timeZone }: Volunteers) =>
      isWorksNow(workTime, timeZone) ? (
        <Badge color="green" />
      ) : (
        <Badge color="red" />
      ),
    width: 10,
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    render: (phone: number) => <a href={`tel:+${phone}`}>{phone}</a>,
  },
  {
    title: "График",
    dataIndex: "workTime",
    key: "workTime",
    render: (workTime: WorkTime[], { timeZone }: Volunteers) => (
      <WorkTimeRenderer workTime={workTime} timeZone={timeZone} />
    ),
  },
];

export default App;
